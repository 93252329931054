<template>
  <div
    class="player-list px-12 py-8"
  >
    <!-- Header Tools -->
    <v-btn v-if="!filteredPlayers.length && projectId" color="primary" outlined class="ml-3" min-height="40" @click="showAddCompetitorDialog = true">
      <v-icon left v-text="'add_box'" /> {{ $t('project.academy.users.addCompetitor') }}
    </v-btn>
    <div v-if="filteredPlayers.length" class="d-flex justify-end align-center mb-4">
      <v-combobox
        v-if="isAcademy"
        v-on-clickaway="closeMenu"
        :value="selectedTeams"
        :items="teams"
        item-text="fullTeamName"
        outlined
        dense
        hide-details
        multiple
        :label="$t('common.team')"
        append-icon="mdi-chevron-down"
        class="remove__border mr-3"
        color="primary"
        :menu-props="{ closeOnContentClick: true }"
        @input="selectedTeams => $store.commit('projectUsers/updatePlayerListFilters', { selectedTeams, search })"
        @change="isMenuOpen = true"
        @mousedown="isMenuOpen = true"
      >
        <template #selection="{ item, index }">
          <!-- open dropdown -->
          <v-chip
            v-if="selectedTeams.some(team => team.id === item.id) && isMenuOpen"
            close
            color="primary"
            outlined
            @click:close="$store.commit('projectUsers/updatePlayerListFilters', { selectedTeams: selectedTeams.filter(team => team.id !== item.id), search })"
          >
            <span>
              {{ item.fullTeamName }}
            </span>
          </v-chip>
          <!-- closed dropdown -->
          <span
            v-else-if="index === 0 && !isMenuOpen"
            class="text-truncate"
            style="max-width: 15rem;"
          >
            {{ item.fullTeamName }}
          </span>
          <span
            v-else-if="index === 1 && !isMenuOpen"
            class="grey--text text-caption"
          >
            (+{{ selectedTeams.length - 1 }})
          </span>
        </template>
      </v-combobox>
      <!-- Search -->
      <span>
        <v-text-field
          :value="search"
          :label="$t('common.search')"
          prepend-inner-icon="search"
          tag="span"
          outlined
          dense
          hide-details
          @input="search => $store.commit('projectUsers/updatePlayerListFilters', { selectedTeams, search })"
        />
      </span>
      <v-btn color="primary" outlined class="ml-3" min-height="40" @click="showAddCompetitorDialog = true">
        <v-icon left v-text="'add_box'" /> {{ $t('project.academy.users.addCompetitor') }}
      </v-btn>
      <export-to-csv
        :file-name-preffix="`${project.name} - Subscribers`"
        action-name="projectUsers/getSubscribersCsv"
        :action-params="{ organizationId, projectId }"
        :button-text="$t('common.exportToCsv')"
      />
    </div>
    <v-data-table v-if="filteredPlayers.length"
                  :headers="headers"
                  :items="filteredPlayers"
                  :sort-by="['name']"
                  :sort-desc="false"
                  :search="search"
                  dense
                  class="table--has-pointer" @click:row="(row) => $router.push({ name: 'project-player', params: { playerId: row.id } })"
    >
      <template #[`item.avatar`]="{ item }">
        <v-avatar size="36">
          <img v-if="item.avatar" alt="Avatar" :src="item.avatar">
          <v-icon v-else color="primary" v-text="'person'" />
        </v-avatar>
      </template>
      <template #[`item.name`]="{ item }">
        <span class="text-capitalize" v-text="item.name" />
      </template>
      <template #[`item.email`]="{ item }">
        <span v-text="item.email.slice(- 10) !== '@owqlo.biz' ? item.email : '-'" />
      </template>
      <template #[`item.team`]="{ item }">
        <v-tooltip top color="white">
          <template #activator="{ on, attrs }">
            <span class="d-inline-block text-truncate" v-bind="attrs" v-on="on" v-text="item.team" />
          </template>
          <div class="tooltip-text pa-2" v-text="item.team" />
        </v-tooltip>
      </template>
      <template #[`item.chevron`]="{ item }">
        <v-btn icon>
          <v-icon color="primary">
            chevron_right
          </v-icon>
        </v-btn>
      </template>
      <template #[`item.paymentStatus`]="{ item }">
        <v-chip
          v-if="item.paymentStatus === 'pending'"
          outlined
          label
          color="warning"
          style="min-width: 5rem;"
        >
          {{ $t('project.academy.users.paymentPending') }}
        </v-chip>
        <v-chip
          v-else-if="item.paymentStatus === 'accepted'"
          outlined
          label
          color="success"
          style="min-width: 5rem;"
        >
          {{ $t('project.academy.users.paymentAccepted') }}
        </v-chip>
      </template>
    </v-data-table>
    <empty-list v-else :content="$t('organization.sections.people')" />
    <add-player-dialog
      v-if="showAddCompetitorDialog" :emails-players="emailsPlayers"
      :organization-id="organizationId" :project-id="projectId" @close-dialog="showAddCompetitorDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import i18nCountries from '@/utils/mixins/i18nCountries'

export default {
  name: 'PlayerList',
  components: {
    EmptyList: () => import('@/components/EmptyList'),
    ExportToCsv: () => import('@/components/ExportToCsv'),
    AddPlayerDialog: () => import('@/modules/projectUsers/AddPlayerDialog/AddPlayerDialog'),
  },
  mixins: [clickaway, i18nCountries],
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'project-player') this.$store.commit('projectUsers/updatePlayerListFilters', { selectedTeams: [], search: null })
    next()
  },
  props: {
    organizationId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      players: [],
      isMenuOpen: false,
      showAddCompetitorDialog: false,
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters({ isAcademy: 'project/isAcademy' }),
    ...mapGetters({ dbPlayers: 'projectUsers/rawPlayers' }),
    ...mapGetters({ teams: 'academy/teams' }),
    ...mapGetters({ filters: 'projectUsers/playerListFilters' }),
    selectedTeams: ({ filters }) => filters.selectedTeams,
    search: ({ filters }) => filters.search,
    headers({ isAcademy, project }) {
      return [
        { text: this.$t('user.fields.avatar'), value: 'avatar' },
        { text: this.$t('user.fields.name'), value: 'name' },
        { text: this.$t('user.fields.email'), value: 'email' },
        { text: this.$t('user.fields.country'), value: 'country' },
        { text: this.$t('user.fields.birthdate'), value: 'birthdate', filterable: false },
        ...(this.isAcademy ? [{ text: this.$t('common.team'), value: 'team' }] : []),
        ...(this.project?.features?.price ? [{ text: this.$t('user.paymentStatus.title'), value: 'paymentStatus' }] : []),
        { text: '', value: 'chevron', filterable: false, sortable: false },
      ]
    },
    filteredPlayers({ players, selectedTeams }) {
      if (selectedTeams.length) {
        return players
          ?.filter(player => selectedTeams.map(team => team.id).some(team => player.team?.includes(this.teamLabel(team))))
      }
      return players
    },
    emailsPlayers: ({ filteredPlayers }) => filteredPlayers.map(p => p.email),
  },
  watch: {
    dbPlayers(dbPlayers) { this.getPlayers(dbPlayers) },
    countries() { this.getPlayers(this.dbPlayers) },
  },
  async created() {
    const { organizationId, projectId } = this
    this.$store.commit('loader/show')
    if (!this.dbPlayers.length) await this.$store.dispatch('projectUsers/bindPlayers', { organizationId, projectId })
    await this.setCountriesLocale(this.$store.state.locale)
    await this.getPlayers(this.dbPlayers)
    if (this.$route.meta.scrollYPosition) window.scrollTo(0, this.$route.meta.scrollYPosition)
    this.$store.commit('loader/hide')
  },
  methods: {
    async getPlayers(dbPlayers) {
      const { projectId } = this
      const fetchedAndFormatted = await Promise.all(dbPlayers.map(async player => {
        let paymentStatus = null
        if (this.project?.features?.price) {
          const { purchaseId } = await this.$store.dispatch(
            'user/readMembershipInfo',
            { id: player.id, role: 'subscriber', entityType: 'project', entityId: projectId },
          )
          const purchase = purchaseId && await this.$store.dispatch('user/readPurchase', { purchaseId })
          paymentStatus = ['complete', 'upToDate'].includes(purchase?.paymentStatus)
            ? this.$t('users.paymentStatus.upToDate')
            : this.$t('users.paymentStatus.pending')
        }

        const { birthdate: { day, month, year } } = player

        return {
          ...player,
          name: `${player.firstName} ${player.lastName}`.trim(),
          birthdate: `${day}/${month}/${year}`,
          country: this.countries[player.country],
          team: this.formatTeam(player),
          paymentStatus,
        }
      }))
      this.players = fetchedAndFormatted?.sort((a, b) => a.firstName.localeCompare(b.firstName))
    },
    formatTeam(player) {
      const { organizationId, projectId } = this
      return player.roles.byProperty[organizationId].byProject[projectId]?.byTeam
        ? this.teamLabel(Object.keys(player.roles.byProperty[organizationId].byProject[projectId].byTeam).find(team => team))
        : null
    },
    teamLabel(team) {
      const { teams } = this
      const teamDoc = teams.find(t => t.id === team)
      return teamDoc ? `${teamDoc.fullTeamName} ` : null
    },
    closeMenu() {
      this.isMenuOpen = false
    },
  },
}
</script>

<style lang="scss">

.player-list {
  .remove__border {
    max-width: 25rem;
  }

  .remove__border > .v-input__control > .v-input__slot {
    margin-bottom: 0 !important;
  }

  .v-avatar img {
    width: 100%;
    object-fit: cover;
  }

  .v-data-table {
    tr {
      td {
        padding: 8px 16px 8px 16px !important;
        cursor: pointer;
      }
    }
  }

}

</style>
